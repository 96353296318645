import React from 'react';
import {FormikProps} from 'formik';
import styled from 'styled-components';
import TextareaAutosize from 'react-textarea-autosize';
import FormInputWrapper from './FormInputWrapper';

const InputTextWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 4px;
  margin-bottom: 8px;

  textarea {
    resize: none;
  }
`;

const StyledInput = styled.textarea`
  flex-grow: 1;
  background-color: transparent;
  border: none;
  font-size: 18px;
  padding: 0;
  overflow: hidden;
  line-height: 1.3;
`;

type Props = {
  id: string;
  brandStyle: BrandStyle;
  label: string;
  helperText?: string;
  optional?: boolean;
  style?: React.CSSProperties;
  inputStyle?: React.CSSProperties | any;
  autoFocus?: boolean;
  formikProps: Partial<FormikProps<any>>;
  layout?: boolean;
  ExtraComponentAfterInput?: React.ReactNode;
  disabled?: boolean;
};

const FormTextareaInput = ({
  id,
  brandStyle,
  label,
  helperText,
  optional,
  style,
  autoFocus,
  formikProps,
  inputStyle,
  layout = true,
  ExtraComponentAfterInput,
  disabled
}: Props) => {
  const value = formikProps.values[id];

  return (
    <FormInputWrapper
      style={style}
      brandStyle={brandStyle}
      id={id}
      label={value ? label : undefined}
      errorMessage={formikProps.errors[id]}
      helperText={helperText}
      optional={optional}
      touched={formikProps.touched[id]}
      layout={layout}
    >
      <InputTextWrapper>
        <StyledInput
          style={inputStyle}
          disabled={disabled}
          placeholder={value ? undefined : label}
          autoFocus={autoFocus}
          as={TextareaAutosize}
          minRows={3}
          onChange={formikProps.handleChange}
          onBlur={formikProps.handleBlur}
          value={value}
          id={id}
        />
      </InputTextWrapper>

      {ExtraComponentAfterInput}
    </FormInputWrapper>
  );
};

export default FormTextareaInput;
