import {useState, useEffect} from 'react';

const useWidthBreakpoints = (breakpoints: number[], defaultBreakpoint: null | number = null): number | null => {
  if (typeof window === 'undefined') {
    return defaultBreakpoint;
  }

  const [activeBreakpoint, setActiveBreakpoint] = useState<number | null>(defaultBreakpoint);

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      const activeIndex = breakpoints.findIndex((breakpoint) => width < breakpoint);
      const adjustedIndex = activeIndex === -1 ? breakpoints.length : activeIndex;

      if (adjustedIndex === activeBreakpoint) return;

      setActiveBreakpoint(adjustedIndex);
    };

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, [activeBreakpoint, breakpoints]);

  return activeBreakpoint;
};

export default useWidthBreakpoints;
