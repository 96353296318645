import {useState, useEffect} from 'react';
import {useSearchParams} from 'next/navigation';

type ModalType = 'partner' | 'contact';

const useLandingContactModal = () => {
  const [modalType, setModalType] = useState<ModalType>(null);
  const [isModalOpened, setIsModalOpened] = useState(false);

  const searchParams = useSearchParams();

  const openContactUs = searchParams.get('openContactUs');

  useEffect(() => {
    if (openContactUs === '1') {
      setModalType('contact');
      setIsModalOpened(true);
    }
  }, [openContactUs]);

  const closeModal = () => {
    setIsModalOpened(false);
  };

  const openModalOfType = (type: ModalType) => () => {
    setIsModalOpened(true);
    setModalType(type);
  };

  return {modalType, isModalOpened, closeModal, openModalOfType};
};

export default useLandingContactModal;
