import {twMerge} from 'tailwind-merge';

type ComponentProps = {
  children?: React.ReactNode;
  className?: string;
  id?: string;
};

export const WidthContainer = ({children, className, id}: ComponentProps) => {
  return (
    <div className={twMerge('width-container w-full max-w-[1400px] px-[--side-padding] mx-auto', className)} id={id}>
      {children}
    </div>
  );
};

export const TwoColumnWidthContainer = ({children, className}: ComponentProps) => {
  return (
    <WidthContainer
      className={twMerge(
        'grid gap-10 grid-cols-[auto_minmax(250px,28%)] pb-20 mt-10 max-[800px]:flex max-[800px]:flex-col-reverse max-[800px]:gap-[unset] max-[800px]:[&>*:nth-child(2)]:mb-10',
        className
      )}
    >
      {children}
    </WidthContainer>
  );
};

export const LandingPill = ({children, className, id}: ComponentProps) => {
  return (
    <span
      id={id}
      className={twMerge(
        'text-xs bg-[rgba(9,140,148,0.1)] text-[--panelista-color] font-semibold uppercase py-2 px-3 rounded-full tracking-wide',
        className
      )}
    >
      {children}
    </span>
  );
};

export const SectionTitle = ({children, className, id}: ComponentProps) => {
  return (
    <h2
      id={id}
      className={twMerge(
        'font-heading font-extrabold text-5xl max-[900px]:text-4xl max-[600px]:text-3xl leading-[1.1] tracking-tight max-[500px]:text-left',
        className
      )}
    >
      {children}
    </h2>
  );
};
