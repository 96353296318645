import Link from 'next/link';
import {twMerge} from 'tailwind-merge';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import {type ForwardedRef, Fragment, forwardRef} from 'react';
import BaseButton from './BaseButton';
import RemixIcon from './RemixIcon';

export type DropdownRichMenuItem = {
  component?: React.ReactNode;
  text?: string;
  description?: string;
  onClick?: () => void;
  iconComponent?: React.ReactNode;
  iconName?: string;
  icon?: any;
  groupType?: 'radio';
  groupId?: string;
  isCheckbox?: boolean;
  isChecked?: boolean;
  value?: string;
  setValue?: (value: string) => void;
  setChecked?: (value: boolean) => void;
  items?: DropdownMenuItem[];
  color?: string;
  disabled?: boolean;
  href?: string;
  target?: string;
  locale?: string;
};

export type DropdownMenuItem = 'separator' | DropdownRichMenuItem;

const Separator = () => <DropdownMenu.Separator className="h-px my-[5px] bg-[--border-color]" />;

type MenuItemProps = {
  item: DropdownMenuItem;
};

const MenuItemContent = forwardRef(({item}: MenuItemProps, ref: ForwardedRef<any>) => {
  if (item === 'separator') return <Separator />;

  if (item.component) return item.component;

  const content = (
    <>
      <div className="flex items-center text-sm font-medium text-[--dark-text] leading-snug" ref={ref}>
        {item.iconComponent}

        {item.iconName || item.icon ? (
          <RemixIcon
            className="mr-2"
            icon={item.icon}
            name={item.iconName}
            color={item.color || 'var(--dark-text)'}
            size={18}
          />
        ) : null}

        <span className="item-text" style={{color: item.color || 'var(--dark-text)'}}>
          {item.text}
        </span>
      </div>

      {item.description ? (
        <span className="text-xs text-[--gray-text] leading-tight mt-1">{item.description}</span>
      ) : null}
    </>
  );

  return content;
});

const itemClassName =
  'flex flex-col rounded py-2 px-3 relative cursor-pointer mx-1 first:mt-1 last:mb-1 data-[highlighted]:bg-[--secondary-button-bg-color] data-[highlighted]:active:bg-[--button-pressed-color] data-[disabled]:opacity-50';

type Props = {
  children?: React.ReactNode;
  items: DropdownMenuItem[];
  align?: 'start' | 'end' | 'center';
  contentStyle?: React.CSSProperties;
  activeColor?: string;
  triggerStyle?: React.CSSProperties;
  className?: string;
  triggerClassName?: string;
  disabled?: boolean;
};

const Dropdown = ({
  children,
  items,
  align = 'start',
  contentStyle,
  activeColor = 'var(--black-text)',
  triggerStyle,
  className,
  triggerClassName,
  disabled
}: Props) => {
  return (
    <DropdownMenu.Root>
      <DropdownMenu.Trigger asChild disabled={disabled}>
        {children || (
          <BaseButton
            animated
            className={twMerge('rounded-full h-[38px] w-[38px]', triggerClassName)}
            style={triggerStyle}
            variant="text"
            disabled={disabled}
          >
            <RemixIcon name="more-2-fill" color="var(--gray-text)" size={18} />
          </BaseButton>
        )}
      </DropdownMenu.Trigger>

      <DropdownMenu.Content
        className={twMerge(
          'bg-white shadow-tall-hover border border-solid border-[--border-color-hover] rounded-lg min-w-[180px] origin-[--radix-dropdown-menu-content-transform-origin] z-10 max-h-[--radix-dropdown-menu-content-available-height] overflow-auto data-[state=open]:animate-[show-up_0.2s_cubic-bezier(0.22,1,0.36,1)]',
          className
        )}
        style={contentStyle}
        align={align}
        sideOffset={4}
        collisionPadding={8}
      >
        {items.map((item, index) => {
          if (item === 'separator') return <Separator key={index} />;

          if (item.groupType === 'radio') {
            return (
              <DropdownMenu.RadioGroup value={item.value} onValueChange={item.setValue} key={index}>
                {(item.items || []).map((radioItem, index) => {
                  if (radioItem === 'separator') return <Separator key={index} />;

                  return (
                    <DropdownMenu.RadioItem
                      value={radioItem.value || ''}
                      key={`radio_${index}`}
                      className={twMerge(itemClassName, 'flex-row')}
                    >
                      <div className="w-5 shrink-0">
                        <DropdownMenu.ItemIndicator
                          forceMount
                          className="[&>*]:hidden [&>.selected]:data-[state=checked]:block [&>.inactive]:data-[state=unchecked]:block"
                        >
                          <RemixIcon
                            name="radio-button-line"
                            size={18}
                            color={radioItem.color || activeColor}
                            className="-ml-1 mt-[2px] selected"
                          />

                          <RemixIcon
                            name="checkbox-blank-circle-line"
                            size={18}
                            color={radioItem.color || 'var(--light-gray-text)'}
                            className="-ml-1 mt-[2px] inactive"
                          />
                        </DropdownMenu.ItemIndicator>
                      </div>

                      <div className="flex flex-col">
                        <MenuItemContent item={radioItem} />
                      </div>
                    </DropdownMenu.RadioItem>
                  );
                })}
              </DropdownMenu.RadioGroup>
            );
          }

          if (item.isCheckbox) {
            return (
              <DropdownMenu.CheckboxItem
                checked={item.isChecked}
                key={`checkbox_${index}`}
                className={twMerge(itemClassName, 'flex-row')}
                onCheckedChange={item.setChecked}
                onSelect={(e) => e.preventDefault()}
              >
                <div className="w-6 shrink-0">
                  <DropdownMenu.ItemIndicator
                    forceMount
                    className="[&>*]:hidden [&>.selected]:data-[state=checked]:block [&>.inactive]:data-[state=unchecked]:block"
                  >
                    <RemixIcon name="checkbox-line" size={18} color={activeColor} className="-ml-1 mt-[2px] selected" />

                    <RemixIcon
                      name="checkbox-blank-line"
                      size={18}
                      color="var(--light-gray-text)"
                      className="-ml-1 mt-[2px] inactive"
                    />
                  </DropdownMenu.ItemIndicator>
                </div>

                <div className="flex flex-col grow">
                  <MenuItemContent item={item} />
                </div>
              </DropdownMenu.CheckboxItem>
            );
          }

          const LinkWrapper = item.href ? Link : Fragment;
          const linkProps: any = item.href ? {href: item.href, target: item.target} : {};

          const onClick = (e: Event) => {
            if (item.disabled) return;
            if (item.onClick) item.onClick();
          };

          return (
            <DropdownMenu.Item
              key={index}
              className={itemClassName}
              onSelect={onClick}
              disabled={item.disabled}
              asChild={Boolean(item.href)}
            >
              <LinkWrapper {...linkProps}>
                <MenuItemContent item={item} />
              </LinkWrapper>
            </DropdownMenu.Item>
          );
        })}
      </DropdownMenu.Content>
    </DropdownMenu.Root>
  );
};

export default Dropdown;
