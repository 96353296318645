import styled from 'styled-components';
import {highlightColor, panelistaLightColor} from 'src/utils/constants';
import {WidthContainer} from './landing/LandingComponents';

export const MainTitle = styled.h1`
  font-family: var(--font-jakarta);
  font-weight: 800;
  font-size: 66px;
  letter-spacing: -0.02em;
  line-height: 1.1;
  max-width: 680px;

  @media (max-width: 900px) {
    font-size: 54px;
  }

  @media (max-width: 600px) {
    font-size: 44px;
  }
`;

export const SectionTitleLegacy = styled.h2`
  font-family: var(--font-jakarta);
  font-weight: 800;
  font-size: 48px;
  letter-spacing: -0.02em;
  line-height: 1.1;
  margin: 30px 0;

  @media (max-width: 900px) {
    font-size: 40px;
    margin: 20px 0;
  }

  @media (max-width: 600px) {
    font-size: 32px;
    margin: 20px 0;
  }
`;

export const LandingSubHeading = styled.p`
  width: 100%;
  font-size: 20px;
  max-width: 720px;
  text-align: center;
  margin-bottom: 60px;

  @media (max-width: 700px) {
    font-size: 18px;
    margin-bottom: 40px;
  }

  @media (max-width: 500px) {
    text-align: left;
    font-size: 16px;
    margin-bottom: 30px;
  }
`;

export const SmallSectionTitle = styled(SectionTitleLegacy)`
  font-size: 24px;
  letter-spacing: initial;
  margin: 10px 0;
`;

export const DemoButton = styled.div`
  font-family: var(--font-jakarta);
  font-weight: 700;
  user-select: none;
  line-height: 1;
  color: var(--black-text);
  font-size: 16px;
  border: none;
  text-decoration: none;
  background-color: ${panelistaLightColor};
  padding: 16px 20px;
  border-radius: 8px;

  transition: filter 0.3s, transform 0.1s, opacity 0.1s;

  &:hover {
    opacity: 1;
    filter: brightness(105%);
    transform: translateY(-1px);
  }
`;

export const LandingButton = styled.a`
  font-family: var(--font-jakarta);
  font-weight: 700;
  text-decoration: none;
  user-select: none;
  line-height: 1.2;
  color: var(--black-text);
  text-align: center;
  font-size: 16px;
  padding: 4px 8px;
  background-color: transparent;
  border: none;

  transition: filter 0.3s, transform 0.1s, opacity 0.1s;

  &:hover {
    opacity: 0.75;
  }
`;

export const LandingBigButton = styled(LandingButton)<{disabled?: boolean; onClick?: any}>`
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--panelista-color);
  color: white;
  min-height: 56px;
  padding: 0 30px;
  border-radius: 8px;
  flex-shrink: 0;
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
  transition: filter 0.3s, transform 0.1s;

  &:hover {
    opacity: 1;
    filter: brightness(105%);
    transform: translateY(-1px);
  }

  @media (max-width: 900px) {
    height: 46px;
    padding: 0 20px;
    font-size: 16px;
  }
`;

export const LandingBigSecondaryButton = styled(LandingBigButton)`
  background-color: transparent;
  color: var(--panelista-color);
  border: 2px solid var(--panelista-color);
`;

export const TwoColumnWidthContainer = styled(WidthContainer)`
  max-width: var(--manage-page-width);
  margin-top: 40px;
  padding-bottom: 80px;
  display: grid;
  gap: 40px;
  grid-template-columns: auto minmax(250px, 28%);

  @media (max-width: 800px) {
    display: flex;
    flex-direction: column-reverse;
    gap: unset;

    & > *:nth-child(2) {
      margin-bottom: 40px;
    }
  }
`;

const SectionFeatureSide = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  padding: 80px 20px;
`;

export const SectionFeatureLeftSide = styled(SectionFeatureSide)`
  padding: 80px 50px 80px 0;

  @media (max-width: 900px) {
    justify-content: flex-start;
    padding-left: 20px;
  }

  @media (max-width: 700px) {
    padding: 120px 20px 0 20px;
    justify-content: center;
  }
`;

export const SectionFeatureRightSide = styled(SectionFeatureSide)`
  @media (max-width: 900px) {
    padding-left: 50px;
    padding-right: 30px;
  }

  @media (max-width: 700px) {
    padding: 220px 20px 0 20px;
    margin-bottom: 60px;
    justify-content: center;
  }

  @media (max-width: 450px) {
    padding: 200px 0 40px 0;
    margin-bottom: 40px;
  }
`;

export const SectionTextSide = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 100px 0;
  width: 50%;

  @media (max-width: 700px) {
    width: 100%;
    padding: 0;
  }
`;

export const TextSideContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  max-width: 440px;

  p {
    font-size: 20px;
    color: var(--dark-text);

    strong {
      font-weight: normal;
      background-color: ${highlightColor};
      padding: 2px 4px;
      border-radius: 4px;
    }
  }

  @media (max-width: 700px) {
    max-width: 100%;
    padding: 60px 0;

    p {
      font-size: 16px;
    }
  }

  @media (max-width: 500px) {
    padding: 40px 0;
  }
`;

export const HandWriting = styled.span`
  font-family: 'Abstract Handwriting', sans-serif;
  font-size: 24px;
`;

export const HowItWorksSection = styled.div`
  background-color: ${panelistaLightColor};
  padding: 50px 0;
  display: flex;
  flex-direction: column;
  align-items: center;

  .width-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  & > ${SectionTitleLegacy} {
    text-align: center;

    margin-bottom: 60px;
    padding: 0 var(--side-padding);

    @media (max-width: 800px) {
      margin-bottom: 50px;
      margin-top: 0;
    }

    @media (max-width: 500px) {
      text-align: left;
      width: 100%;
    }
  }

  @media (max-width: 500px) {
    padding: 40px 0 0 0;
  }
`;

export const PhoneShape = styled.div<{hoverable?: boolean}>`
  position: relative;
  width: 270px;
  height: 520px;
  border-radius: 34px;
  border: 5px solid #d7ebed;
  background-color: white;
  box-shadow: 0px 14px 54px 0 rgba(21, 134, 140, 0.2);
  overflow: hidden;
  transition: transform 0.4s;
  transition-timing-function: cubic-bezier(0.25, 1, 0.5, 1);
  transform: translate3d(0, 0, 0);

  &::after {
    content: '';
    position: absolute;
    top: 0;
    width: 110px;
    height: 14px;
    left: calc(50% - 55px);
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    background-color: #d7ebed;
  }

  &:hover {
    transform: ${(props) =>
      props.hoverable ? 'perspective(1000px) rotateX(3deg) rotateY(-14deg) rotateZ(3deg)' : 'translate3d(0, 0, 0)'};
  }

  &:active {
    transform: ${(props) => (props.hoverable ? 'scale(0.95)' : 'translate3d(0, 0, 0)')};
  }

  @media (max-width: 500px) {
    transform: scale(0.8);
    margin-top: -70px;
    margin-bottom: -70px;

    &:hover {
      transform: scale(0.8);
    }

    &:active {
      transform: ${(props) => (props.hoverable ? 'scale(0.75)' : 'scale(0.8)')};
    }
  }
`;

export const BackgroundGradient = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 130vh;

  background-image: radial-gradient(700px 400px at -5% 50%, rgba(243, 183, 44, 0.1) 0%, rgba(243, 183, 44, 0) 100%),
    radial-gradient(70vw 50vh at 110vw 20vh, rgba(29, 146, 153, 0.2) 0%, rgba(29, 146, 153, 0) 100%);

  @media (max-width: 940px) {
    background-image: radial-gradient(50% 70vh at -5% 50vh, rgba(243, 183, 44, 0.08) 0%, rgba(243, 183, 44, 0) 80%),
      radial-gradient(70vw 100vh at 110vw 0vh, rgba(29, 146, 153, 0.2) 0%, rgba(29, 146, 153, 0) 80%);
  }
`;

export const DemoDescription = styled.p`
  font-size: 20px;
  text-align: center;
  margin: 30px 0;
  max-width: 650px;
  width: 100%;

  @media (max-width: 600px) {
    margin: 20px 0;
    font-size: 16px;
    text-align: left;
  }
`;

export const DemoAvatarsContainer = styled.div`
  display: flex;
  width: 100%;
  max-width: 1200px;
  margin-top: 24px;
  justify-content: center;
`;

export const DemoAvatar = styled.div`
  display: flex;
  margin: 0 16px;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  box-shadow: 0px 8px 30px 0 rgba(21, 134, 140, 0.15);
`;
