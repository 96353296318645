type UseCase = {
  body: string;
  caseText: string;
  author: string;
  imageSrc: string;
  company: string;
  link?: string;
};

const getUseCases = (t: any): UseCase[] => [
  {
    body: t.rich('website.use case vasttrafik body', {strong: (chunks) => <strong>{chunks}</strong>}),
    caseText: t.rich('website.use case vasttrafik case text', {i: (chunks) => <i>{chunks}</i>, br: () => <br />}),
    author: t.rich('website.use case vasttrafik person', {
      strong: (chunks) => <strong>{chunks}</strong>,
      br: () => <br />
    }),
    imageSrc: '/images/useCaseVasttrafikImage.png',
    company: 'Västtrafik',
    link: t('website.use case vasttrafik link')
  },
  {
    body: t.rich('website.use case bonnier body', {strong: (chunks) => <strong>{chunks}</strong>}),
    caseText: t.rich('website.use case bonnier case text', {i: (chunks) => <i>{chunks}</i>, br: () => <br />}),
    author: t.rich('website.use case bonnier person', {
      strong: (chunks) => <strong>{chunks}</strong>,
      br: () => <br />
    }),
    imageSrc: '/images/useCaseBonnierImage.png',
    company: 'Bonnier',
    link: t('website.use case bonnier link')
  },
  {
    body: t.rich('website.use case fractal body', {strong: (chunks) => <strong>{chunks}</strong>}),
    caseText: t.rich('website.use case fractal case text', {i: (chunks) => <i>{chunks}</i>, br: () => <br />}),
    author: t.rich('website.use case fractal person', {
      strong: (chunks) => <strong>{chunks}</strong>,
      br: () => <br />
    }),
    imageSrc: '/images/useCaseFractalImage.png',
    company: 'Fractal',
    link: t('website.use case fractal link')
  },
  {
    body: t.rich('website.use case sodra body', {strong: (chunks) => <strong>{chunks}</strong>}),
    caseText: t.rich('website.use case sodra case text', {i: (chunks) => <i>{chunks}</i>, br: () => <br />}),
    author: t.rich('website.use case sodra person', {
      strong: (chunks) => <strong>{chunks}</strong>,
      br: () => <br />
    }),
    imageSrc: '/images/useCaseSodraImage.png',
    company: 'Södra',
    link: t('website.use case sodra link')
  },
  {
    body: t.rich('website.use case wayke body', {strong: (chunks) => <strong>{chunks}</strong>}),
    caseText: t.rich('website.use case wayke case text', {i: (chunks) => <i>{chunks}</i>, br: () => <br />}),
    author: t.rich('website.use case wayke person', {
      strong: (chunks) => <strong>{chunks}</strong>,
      br: () => <br />
    }),
    imageSrc: '/images/useCaseWaykeImage.png',
    company: 'Wayke',
    link: t('website.use case wayke link')
  }
];

export default getUseCases;
