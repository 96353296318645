type Props = {
  onClick: () => void;
  isActive: boolean;
};

const HamburgerButton = ({onClick, isActive}: Props) => {
  return (
    <button
      className="w-[45px] h-[45px] flex justify-center items-center border-none outline-none bg-transparent flex-col"
      onClick={onClick}
      aria-label="Menu"
    >
      <div
        className="w-6 h-[2px] bg-[--black-text] my-[2px] transition"
        style={{
          transform: isActive ? 'rotate(45deg) translate(4.6px, 4.6px)' : 'rotate(0deg)',
          backgroundColor: isActive ? 'white' : 'var(--black-text)'
        }}
      />

      <div className="w-6 h-[2px] bg-[--black-text] my-[2px] transition" style={{opacity: isActive ? 0 : 1}} />

      <div
        className="w-6 h-[2px] bg-[--black-text] my-[2px] transition"
        style={{
          transform: isActive ? 'rotate(-45deg) translate(4px, -4px)' : 'rotate(0deg)',
          backgroundColor: isActive ? 'white' : 'var(--black-text)'
        }}
      />
    </button>
  );
};

export default HamburgerButton;
