import styled from 'styled-components';
import {panelistaPrimaryColor} from 'src/utils/constants';

export const ColumnSection = styled.div`
  flex-grow: 1;
  font-size: 20px;
  display: flex;
  width: 100%;
  align-items: center;

  ul li {
    margin: 24px 0;
    padding-right: 100px;
    position: relative;
    padding-left: 44px;
  }

  ul li::before {
    content: '';
    left: 0;
    top: 0;
    position: absolute;
    width: 30px;
    height: 30px;
    background-color: white;
    border-radius: 15px;
    background-image: url(/images/panelistaCheck.svg);
    background-position: center;
    background-repeat: no-repeat;
  }

  @media (max-width: 820px) {
    width: 100%;

    &:last-child {
      margin-bottom: 80px;
    }

    ul li {
      padding-right: 16px;
      font-size: 18px;
    }
  }
`;

export const ThankYouLayoutContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 24px;

  h2 {
    text-align: center;
  }

  p {
    margin-top: 8px;
    font-size: 20px;
    text-align: center;
  }

  svg {
    margin: 24px 0;
  }

  svg g {
    fill: ${panelistaPrimaryColor};
  }
`;
