import Image from 'next/image';

type Props = {
  size?: number;
  pictureSrc: string;
  altPictureSrc?: string;
  priority?: boolean;
  alt: string;
  inView?: boolean;
};

const InteractiveAvatar = ({size = 50, pictureSrc, altPictureSrc, priority, alt, inView}: Props) => {
  return (
    <button
      className="relative group border-none overflow-hidden hover:scale-[1.2] focus:scale-[1.2] transition-transform duration-300 ease-in-out cursor-grab"
      style={{width: size, height: size, borderRadius: size}}
    >
      {altPictureSrc && inView ? <Image src={altPictureSrc} alt={alt} width={0} height={0} /> : null}

      <Image src={pictureSrc} priority={priority} fill sizes="100" alt={alt} />

      {altPictureSrc ? (
        <div className="absolute w-full h-full top-0 left-0 opacity-0 group-hover:opacity-100 group-focus:opacity-100 transition-opacity duration-300 ease-in-out">
          <Image src={altPictureSrc} fill alt={alt} sizes="100" />
        </div>
      ) : null}
    </button>
  );
};

export default InteractiveAvatar;
