const rowClassName =
  'flex justify-between items-center grow-[2] max-[900px]:w-full max-[900px]:max-w-[500px] max-[900px]:justify-evenly';

const RowOfLogos = () => {
  return (
    <div className="relative flex mt-5 mb-7 w-full max-w-[1000px] max-[900px]:flex-col max-[900px]:items-center [&_img]:max-[600px]:scale-[0.8] [&_img]:max-[420px]:scale-[0.6] [&_img]:max-[420px]:-mx-5">
      <div className={rowClassName}>
        <img src="/images/logos/avanzaLogo.svg" width="105" height="38" alt="Avanza logo" />
        <img src="/images/logos/goteborgEnergiLogo.svg" width="67" height="51" alt="Göteborg Energi logo" />
        <img src="/images/logos/bonnierLogo.svg" width="115" height="18" alt="Bonnier logo" />
      </div>

      <div className="grow h-5 max-[400px]:h-2" />

      <div className={rowClassName}>
        <img src="/images/logos/skellefteaLogo.svg" width="127" height="40" alt="Skellefteå Kommun logo" />
        <img src="/images/logos/skanetrafikenLogo.svg" width="162" height="21" alt="Skånetrafiken logo" />
        <img src="/images/logos/agriaLogo.svg" width="98" height="33" alt="Agria logo" />
      </div>
    </div>
  );
};

export default RowOfLogos;
