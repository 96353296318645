import * as Yup from 'yup';
import {Formik} from 'formik';
import styled from 'styled-components';
import {useTranslations} from 'next-intl';
import React, {useState, useEffect} from 'react';
import {LandingBigButton} from 'src/components/landingStyledComponents';
import ResponsiveRadixModal from 'src/components/ResponsiveRadixModal';
import FormTextareaInput from 'src/components/FormTextareaInput';
import {panelistaPrimaryColor} from 'src/utils/constants';
import FormTextInput from 'src/components/FormTextInput';
import contactForm from 'src/client/methods/contactForm';
import getBrandStyle from 'src/utils/getBrandStyle';
import PhoneInput from 'src/components/PhoneInput';
import ClapImage from '../../icons/clapImage.svg';

const LayoutContainer = styled.div`
  padding: 40px 24px 24px 24px;
  max-width: 520px;

  @media (max-width: 600px) {
    padding-bottom: 400px;
  }

  h2 {
    font-family: var(--font-jakarta);
    font-weight: 700;
  }

  p {
    margin-top: 8px;
    margin-bottom: 24px;
  }
`;

const ThankYouLayoutContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 24px;

  h2 {
    text-align: center;
    font-family: var(--font-jakarta);
    font-weight: 700;
  }

  p {
    margin-top: 8px;
    font-size: 20px;
    text-align: center;
  }

  svg {
    margin: 24px 0;
  }

  svg g {
    fill: ${panelistaPrimaryColor};
  }
`;

const getPartnerFormSchema = (t: any) =>
  Yup.object().shape({
    name: Yup.string().max(50, t('common.too long')).required(t('common.required')),
    phone: Yup.string().required(t('common.required')),
    email: Yup.string().max(100, t('common.too long')).required(t('common.required'))
  });

const getContactFormSchema = (t: any) =>
  Yup.object().shape({
    name: Yup.string().max(50, t('common.too long')).required(t('common.required')),
    email: Yup.string().max(100, t('common.too long')).required(t('common.required')),
    message: Yup.string().required(t('common.required'))
  });

type FormType = {
  name?: string;
  phone?: string;
  email?: string;
  message?: string;
};

type ExtraFormData = {
  type?: 'partner' | 'contact';
};

const brandStyle = getBrandStyle({primaryColor: panelistaPrimaryColor});

type Props = {
  closeModal: () => void;
  extraFormData?: ExtraFormData;
};

const LandingContactFormModal = ({closeModal, extraFormData = {}}: Props) => {
  const t = useTranslations();

  const [isSent, setIsSent] = useState(false);
  const FormSchema = extraFormData.type === 'partner' ? getPartnerFormSchema(t) : getContactFormSchema(t);

  const onSubmit = (formValues: FormType) => {
    const body = {...formValues, email: formValues.email, ...extraFormData};

    return contactForm(body).then(() => {
      plausible('Contact form submitted', {props: {type: extraFormData?.type}});
      setIsSent(true);
    });
  };

  useEffect(() => {
    plausible('Contact form opened', {props: {type: extraFormData?.type}});
  }, []);

  return (
    <ResponsiveRadixModal onClose={closeModal} style={{maxWidth: 500}} layout={false}>
      {isSent ? (
        <ThankYouLayoutContainer>
          <ClapImage viewBox="0 0 80 80" style={{width: 120, height: 120}} />
          <h2>{t('website.book demo thank you header')}</h2>
          <p>{t('website.book demo thank you body')}</p>
        </ThankYouLayoutContainer>
      ) : (
        <LayoutContainer>
          <h2>{t('website.contact us')}</h2>
          <p>{t('website.book demo form subheading')}</p>

          <Formik
            validationSchema={FormSchema}
            initialValues={{name: '', phone: '', email: '', message: ''}}
            onSubmit={onSubmit}
          >
            {(formikProps) => {
              const {submitForm} = formikProps;

              return (
                <form>
                  <FormTextInput formikProps={formikProps} brandStyle={brandStyle} id="name" label={t('common.name')} />

                  <FormTextInput
                    formikProps={formikProps}
                    brandStyle={brandStyle}
                    type="email"
                    id="email"
                    label={t('common.email')}
                  />

                  {extraFormData && extraFormData.type !== 'contact' ? (
                    <PhoneInput formikProps={formikProps} id="phone" brandStyle={brandStyle} />
                  ) : null}

                  {extraFormData && extraFormData.type === 'contact' ? (
                    <FormTextareaInput
                      formikProps={formikProps}
                      id="message"
                      brandStyle={brandStyle}
                      label={t('common.message')}
                    />
                  ) : null}

                  <LandingBigButton as="button" type="submit" onClick={submitForm}>
                    {t('common.send')}
                  </LandingBigButton>
                </form>
              );
            }}
          </Formik>
        </LayoutContainer>
      )}
    </ResponsiveRadixModal>
  );
};

export default LandingContactFormModal;
