import {twMerge} from 'tailwind-merge';
import BaseButton from '../BaseButton';

type Props = {
  children: React.ReactNode;
  size?: 'small' | 'large';
  variant?: 'primary' | 'secondary';
  className?: string;
  onClick?: () => void;
  href?: string;
};

const LandingButton = ({children, size = 'large', variant = 'primary', className, onClick, href}: Props) => {
  return (
    <BaseButton
      className={twMerge(
        'font-heading font-semibold',
        variant === 'primary' && 'bg-[--panelista-color]',
        variant === 'secondary' && 'backdrop-blur-sm',
        className
      )}
      onClick={onClick}
      href={href}
      size={size}
      variant={variant}
    >
      {children}
    </BaseButton>
  );
};

export default LandingButton;
