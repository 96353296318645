'use client';

import Link from 'next/link';
import styled from 'styled-components';
import {AnimatePresence} from 'framer-motion';
import {useLocale, useTranslations} from 'next-intl';
import LandingContactFormModal from 'src/pages/landing-page/LandingContactFormModal';
import {LandingButton, SectionTitleLegacy} from '../landingStyledComponents';
import PanelistaLogo from '../../../public/icons/panelistaBiggerLogo.svg';
import useLandingContactModal from 'src/utils/useLandingContactModal';
import {panelistaDarkColor} from 'src/utils/constants';
import {WidthContainer} from './LandingComponents';
import RemixIcon from 'src/components/RemixIcon';

const compactThreshold = 800;

const Container = styled.div`
  background-color: ${panelistaDarkColor};
  color: white;
`;

const FooterContainer = styled(WidthContainer)`
  flex-direction: column;
  display: flex;

  a {
    text-decoration: none;
  }
`;

const LogoText = styled.p`
  margin-top: 8px;
  opacity: 0.7;
  max-width: 280px;
  color: inherit;

  @media (max-width: ${compactThreshold}px) {
    max-width: 430px;
  }
`;

const FooterTitle = styled(SectionTitleLegacy)`
  font-size: 20px;
  margin: 12px 0;
  letter-spacing: initial;
  white-space: nowrap;
`;

const FooterLink = styled(LandingButton)`
  font-size: 16px;
  margin: 12px 0;
  padding: 0;
  opacity: 0.7;
  color: white;
  white-space: nowrap;
  cursor: pointer;
  text-align: left;

  &:hover {
    opacity: 0.85;
  }
`;

const FooterRow = styled.div`
  display: flex;
  padding: 80px 0;

  @media (max-width: ${compactThreshold}px) {
    flex-direction: column;
    padding: 40px 0 20px 0;
  }
`;

const FooterColumn = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 200px;
  flex-shrink: 1;
  margin-left: 16px;

  @media (max-width: ${compactThreshold}px) {
    margin-left: 0;
    margin-bottom: 20px;
    flex-basis: auto;
  }
`;
const FirstColumn = styled(FooterColumn)`
  margin-left: 32px;

  @media (max-width: ${compactThreshold}px) {
    margin-left: 0;
    margin-top: 8px;
    flex-basis: auto;
  }
`;

const LogoColumn = styled(FooterColumn)`
  margin-top: 10px;
  margin-left: 0;
  flex-basis: 360px;

  @media (max-width: ${compactThreshold}px) {
    flex-basis: auto;
  }
`;

const FooterDivider = styled.div`
  background-color: white;
  opacity: 0.15;
  height: 1px;
  width: 100%;
`;

const FooterBottom = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const FooterText = styled.p`
  margin: 30px 0;

  a {
    color: rgba(255, 255, 255, 0.7);
    text-decoration: none;
  }

  a > strong {
    color: white;
    opacity: 0.9;
  }
`;

const LandingFooter = () => {
  const t = useTranslations();
  const locale = useLocale();

  const {isModalOpened, modalType, closeModal, openModalOfType} = useLandingContactModal();

  return (
    <Container>
      <FooterContainer>
        <FooterRow>
          <LogoColumn>
            <Link href="/" prefetch={false} aria-label="Panelista">
              <PanelistaLogo viewBox="0 0 143 33" style={{width: 143}} />
            </Link>
            <LogoText>{t.rich('website.footer body', {br: () => <br />})}</LogoText>
          </LogoColumn>

          <div className="grow" />

          <FirstColumn>
            <FooterTitle>Panelista</FooterTitle>

            <Link href="/panels" prefetch={false}>
              <FooterLink as="div">{t('common.panels')}</FooterLink>
            </Link>
            <Link href="/touchpoints" prefetch={false}>
              <FooterLink as="div">{t('common.touchpoint', {count: 2})}</FooterLink>
            </Link>
            <Link href="/#pricing" prefetch={false}>
              <FooterLink as="div">{t('website.pricing')}</FooterLink>
            </Link>
            <Link href="/#use-cases" prefetch={false}>
              <FooterLink as="div">{t('website.use cases')}</FooterLink>
            </Link>
            <FooterLink target="_blank" rel="noopener" href={`https://articles.panelista.com/${locale}`}>
              {t('website.articles')}
            </FooterLink>
          </FirstColumn>

          <FooterColumn>
            <FooterTitle>{t('website.contact us')}</FooterTitle>
            <Link href="/demo" prefetch={false}>
              <FooterLink as="div">{t('website.book demo')}</FooterLink>
            </Link>
            {/* <Link href="/careers" prefetch={false}>
              <FooterLink as="div">{t('website.careers')}</FooterLink>
            </Link> */}
            <FooterLink onClick={openModalOfType('contact')}>{t('website.contact')}</FooterLink>
            <FooterLink onClick={openModalOfType('partner')}>{t('website.become partner')}</FooterLink>
          </FooterColumn>

          <FooterColumn style={{flexBasis: 'auto'}}>
            <FooterTitle>{t('website.data and terms')}</FooterTitle>
            {locale === 'sv' ? (
              <Link href="/legal/data-protection" prefetch={false}>
                <FooterLink as="div">{t('website.data protection')}</FooterLink>
              </Link>
            ) : null}

            <FooterLink href="/legal/privacy-policy" target="_blank">
              {t('common.privacy policy')}
            </FooterLink>
            <FooterLink href="/legal/terms-of-service" target="_blank">
              {t('common.terms of service')}
            </FooterLink>
          </FooterColumn>
        </FooterRow>

        <FooterDivider />

        <FooterBottom>
          <FooterText>
            <a href="https://propel.se/" target="_blank" rel="noopener">
              {t.rich('website.propel address', {strong: (chunks) => <strong>{chunks}</strong>})}
            </a>
          </FooterText>

          <a
            href="https://www.linkedin.com/company/panelista/"
            target="_blank"
            rel="noopener"
            aria-label="Panelista LinkedIn"
          >
            <RemixIcon name="linkedin-box-fill" color="white" style={{opacity: 0.5}} />
          </a>
        </FooterBottom>
      </FooterContainer>

      <AnimatePresence>
        {isModalOpened ? <LandingContactFormModal closeModal={closeModal} extraFormData={{type: modalType}} /> : null}
      </AnimatePresence>
    </Container>
  );
};

export default LandingFooter;
