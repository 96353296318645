'use client';

import {twMerge} from 'tailwind-merge';
import {useRef, useState, useEffect} from 'react';
import {useLocale, useTranslations} from 'next-intl';
import {usePreventScroll} from '@react-aria/overlays';
import {AnimatePresence, motion} from 'framer-motion';
import {LandingBigButton, LandingButton, DemoButton} from '../landingStyledComponents';
import LandingContactFormModal from 'src/pages/landing-page/LandingContactFormModal';
import {useLocalizedPathname, useLocalizedRouter, Link} from 'src/utils/navigation';
import getDefaultRouteAfterSignIn from 'src/utils/getDefaultRouteAfterSignIn';
import PanelistaLogo from '../../../public/icons/panelistaBiggerLogo.svg';
import useLandingContactModal from 'src/utils/useLandingContactModal';
import useWidthBreakpoints from 'src/utils/useWidthBreakpoints';
import EnglishFlag from '../../icons/language_en.svg';
import SwedishFlag from '../../icons/language_sv.svg';
import type {DropdownRichMenuItem} from '../Dropdown';
import {useUser} from 'src/utils/auth/userAtoms';
import HamburgerButton from './HamburgerButton';
import BaseButton from '../BaseButton';
import Dropdown from '../Dropdown';

const onLogoClick = () => scrollTo({top: 0, behavior: 'smooth'});

const compactThreshold = 1150;

const navLinkStyle: React.CSSProperties = {
  margin: '0 8px',
  textDecoration: 'none'
};

const getLanguageButtons = (setLocale: (locale: string) => void): DropdownRichMenuItem[] => [
  {
    iconComponent: <SwedishFlag height={14} style={{marginRight: 8, borderRadius: 2}} />,
    text: 'Svenska',
    onClick: () => setLocale('sv'),
    locale: 'sv'
  },
  {
    iconComponent: <EnglishFlag height={14} style={{marginRight: 8, borderRadius: 2}} />,
    text: 'English',
    onClick: () => setLocale('en'),
    locale: 'en'
  }
];

const scrollThreshold = 20;

const getItemAnimationProps = (index: number, as?: any) => ({
  as: as || motion.div,
  animate: {opacity: 1, y: 0, transition: {duration: 0.6, delay: index * 0.1, ease: [0.25, 1, 0.5, 1]}},
  initial: {opacity: 0, y: -20, ease: [0.25, 1, 0.5, 1]}
});

const LandingHeader = () => {
  const pathname = useLocalizedPathname();
  const router = useLocalizedRouter();
  const locale = useLocale();

  const {user, isLoadingUser} = useUser();
  const t = useTranslations();
  const visibleRef = useRef(false);
  const [mobileMenuOpened, setMobileMenuOpened] = useState(false);
  const [isScrolled, setScrolled] = useState(!scrollThreshold);
  const widthBreakpoint = useWidthBreakpoints([compactThreshold], 1);

  const {isModalOpened, modalType, closeModal, openModalOfType} = useLandingContactModal();

  const openContactModal = openModalOfType('contact');

  const active = mobileMenuOpened ? 1 : 0;

  const isHome = pathname === '/';

  const setLocale = (locale: Locale) => {
    router.replace(pathname, {locale});
    router.refresh();
  };

  const languageButtons = getLanguageButtons(setLocale);

  usePreventScroll({isDisabled: !mobileMenuOpened});

  useEffect(() => {
    if (widthBreakpoint === 1 && mobileMenuOpened) {
      setMobileMenuOpened(false);
    }
  }, [widthBreakpoint]);

  useEffect(() => {
    const onScroll = () => {
      if (window.scrollY > scrollThreshold && !visibleRef.current) {
        setScrolled(true);
        visibleRef.current = true;
      }

      if (window.scrollY < scrollThreshold && visibleRef.current) {
        setScrolled(false);
        visibleRef.current = false;
      }
    };

    window.addEventListener('scroll', onScroll);

    return () => window.removeEventListener('scroll', onScroll);
  }, []);

  const logoClassName = twMerge(
    'relative w-[143px] cursor-pointer block [&_g]:fill-[--panelista-color] transition-colors max-[1150px]:w-[126px] max-[350px]:mt-1 max-[350px]:w-[96px]',
    active && '[&_g]:fill-white'
  );

  return (
    <>
      <div
        className={twMerge(
          'w-full h-[--landing-header-height] flex items-end fixed top-0 z-[2] border-b border-solid bg-transparent transition border-b-transparent',
          'max-[1150px]:top-[calc(-100%+var(--landing-header-height))] max-[1150px]:h-[calc(100%+2px)]',
          isScrolled && 'bg-[rgba(255,255,255,0.92)]',
          active && 'bg-[rgba(14,73,77,0.92)] max-[1150px]:translate-y-[calc(100%-var(--landing-header-height)-2px)]',
          isScrolled || active
            ? 'saturate-150 backdrop-blur-lg border-b-[--button-pressed-color]'
            : 'backdrop-blur-none'
        )}
      />

      <div className="fixed top-0 z-[4] w-full h-[--landing-header-height] flex justify-between items-center px-[--side-padding]">
        {isHome ? (
          <PanelistaLogo className={logoClassName} onClick={onLogoClick} viewBox="0 0 143 33" />
        ) : (
          <Link href="/" aria-label="Panelista">
            <PanelistaLogo className={logoClassName} viewBox="0 0 143 33" active={active} />
          </Link>
        )}

        <div className="items-baseline relative flex max-[1150px]:hidden">
          <Link href="/panels" prefetch={false} style={navLinkStyle}>
            <LandingButton as="div">{t('common.panels')}</LandingButton>
          </Link>

          <Link href="/touchpoints" prefetch={false} style={navLinkStyle}>
            <LandingButton as="div">{t('common.touchpoint', {count: 2})}</LandingButton>
          </Link>

          <Link href="/#pricing" prefetch={false} style={navLinkStyle}>
            <LandingButton as="div">{t('website.pricing')}</LandingButton>
          </Link>

          <LandingButton
            href={locale === 'sv' ? 'https://artiklar.panelista.com' : 'https://articles.panelista.com'}
            style={navLinkStyle}
          >
            {t('website.articles')}
          </LandingButton>

          <LandingButton as="button" onClick={openContactModal} style={navLinkStyle}>
            {t('website.contact')}
          </LandingButton>
        </div>

        <div className="flex items-center justify-end relative w-[300px] max-[1150px]:hidden gap-2">
          {isLoadingUser ? null : (
            <>
              {user ? (
                <LandingButton href={getDefaultRouteAfterSignIn(user)} style={navLinkStyle}>
                  {t('website.open panelista')}
                </LandingButton>
              ) : (
                <>
                  <LandingButton href="/signin" style={navLinkStyle}>
                    {t('common.sign in')}
                  </LandingButton>

                  <Link href="/demo" prefetch={false} style={{marginLeft: 12, textDecoration: 'none'}}>
                    <DemoButton>{t('website.book demo')}</DemoButton>
                  </Link>

                  <Dropdown items={languageButtons} align="end">
                    <BaseButton
                      aria-label={t('common.language')}
                      iconName="earth-line"
                      iconSize={20}
                      className="hover:opacity-80"
                    />
                  </Dropdown>
                </>
              )}
            </>
          )}
        </div>

        <div className="hidden items-center relative max-[1150px]:flex">
          <HamburgerButton onClick={() => setMobileMenuOpened(!mobileMenuOpened)} isActive={mobileMenuOpened} />
        </div>
      </div>

      <AnimatePresence initial={false}>
        {mobileMenuOpened ? (
          <div className="fixed flex flex-col top-[--landing-header-height] left-0 z-[3] w-full h-[calc(100%-var(--landing-header-height))] p-[--side-padding] pt-0 overflow-auto">
            <div className="flex flex-col py-5 gap-4">
              <motion.div {...getItemAnimationProps(1)}>
                <BaseButton
                  href="/panels"
                  prefetch={false}
                  onClick={() => setMobileMenuOpened(false)}
                  size="large"
                  className="font-heading font-bold text-3xl justify-start px-0"
                  color="white"
                  label={t('common.panels')}
                />
              </motion.div>

              <motion.div {...getItemAnimationProps(2)}>
                <BaseButton
                  href="/touchpoints"
                  prefetch={false}
                  onClick={() => setMobileMenuOpened(false)}
                  size="large"
                  className="font-heading font-bold text-3xl justify-start px-0"
                  color="white"
                  label={t('common.touchpoint', {count: 2})}
                />
              </motion.div>

              <motion.div {...getItemAnimationProps(3)}>
                <BaseButton
                  href="/#pricing"
                  prefetch={false}
                  onClick={() => setMobileMenuOpened(false)}
                  size="large"
                  className="font-heading font-bold text-3xl justify-start px-0"
                  color="white"
                  label={t('website.pricing')}
                />
              </motion.div>

              <motion.div {...getItemAnimationProps(4)}>
                <BaseButton
                  href={locale === 'sv' ? 'https://artiklar.panelista.com' : 'https://articles.panelista.com'}
                  onClick={() => setMobileMenuOpened(false)}
                  size="large"
                  className="font-heading font-bold text-3xl justify-start px-0"
                  color="white"
                  label={t('website.articles')}
                />
              </motion.div>

              <motion.div {...getItemAnimationProps(5)}>
                <BaseButton
                  onClick={openContactModal}
                  size="large"
                  className="font-heading font-bold text-3xl justify-start px-0"
                  color="white"
                  label={t('website.contact')}
                />
              </motion.div>
            </div>

            <div className="grow" />

            {user ? null : (
              <motion.div
                className="flex mb-4 [&_a]:no-underline"
                animate={{opacity: 1, transition: {duration: 0.5, delay: 0.1}}}
                initial={{opacity: 0}}
              >
                {languageButtons.map((languageButton) => {
                  const isActive = locale === languageButton.locale;

                  return (
                    <BaseButton
                      key={languageButton.text}
                      href={languageButton.href}
                      onClick={languageButton.onClick}
                      size="large"
                      className={twMerge(
                        'grow border-2 border-solid border-transparent rounded-full h-auto py-3 gap-3 font-heading font-semibold',
                        isActive && 'border-[--panelista-light-color]'
                      )}
                      locale={languageButton.locale}
                    >
                      {languageButton.iconComponent}
                      <span className="text-white">{languageButton.text}</span>
                    </BaseButton>
                  );
                })}
              </motion.div>
            )}

            <motion.div animate={{opacity: 1, transition: {duration: 0.5, delay: 0.2}}} initial={{opacity: 0}}>
              <LandingBigButton href={user ? getDefaultRouteAfterSignIn(user) : '/signin'}>
                {user ? t('website.open panelista') : t('common.sign in')}
              </LandingBigButton>
            </motion.div>
          </div>
        ) : null}
      </AnimatePresence>

      <AnimatePresence>
        {isModalOpened ? <LandingContactFormModal closeModal={closeModal} extraFormData={{type: modalType}} /> : null}
      </AnimatePresence>
    </>
  );
};

export default LandingHeader;
