'use client';

import styled from 'styled-components';
import {twMerge} from 'tailwind-merge';
import {useTranslations} from 'next-intl';
import RowOfLogos from 'src/pages/landing-page/RowOfLogos';
import {MainTitle} from '../landingStyledComponents';
import {WidthContainer} from './LandingComponents';
import HeroPhoneFeature from './HeroPhoneFeature';
import LandingButton from './LandingButton';

type ContainerProps = {
  children: React.ReactNode;
  className?: string;
};

export const Container = ({children, className}: ContainerProps) => {
  return (
    <div
      className={twMerge(
        'w-full h-screen min-h-[960px] max-h-[1080px] pt-[--landing-header-height] overflow-hidden flex flex-col bg-[radial-gradient(700px_500px_at_-5%_70%,rgba(243,183,44,0.08)0%,rgba(243,183,44,0)100%),radial-gradient(70vw_100%_at_110vw_0vh,rgba(29,146,153,0.2)0%,rgba(29,146,153,0)100%)] max-[940px]:flex-col max-[940px]:h-[initial] max-[940px]:min-h-[initial] max-[940px]:max-h-[initial] max-[940px]:bg-[radial-gradient(50%_30%_at_-5%_70%,_rgba(243,_183,_44,_0.15)_0%,_rgba(243,_183,_44,_0)_100%),radial-gradient(60%_50%_at_110vw_50%,_rgba(29,_146,_153,_0.2)_0%,_rgba(29,_146,_153,_0)_100%)]',
        className
      )}
    >
      {children}
    </div>
  );
};

const StyledWidthContainer = styled(WidthContainer)`
  display: flex;
  flex-grow: 1;
  position: relative;

  @media (max-width: 940px) {
    flex-direction: column;
    align-items: center;
    margin-bottom: 60px;
  }

  @media (max-width: 420px) {
    margin-bottom: 0;
  }
`;

const ContentSide = styled.div`
  position: relative;
  z-index: 1;
  display: flex;
  display: flex;
  justify-content: center;
  flex-direction: column;
  flex-grow: 1;
  width: 1px;
  font-size: 20px;

  @media (max-width: 940px) {
    margin-top: 40px;
    width: 100%;
  }
`;

const FeatureSide = styled.div`
  position: relative;
  width: 1px;
  flex-basis: 700px;
  margin-right: -20px;

  @media (max-width: 1350px) {
    margin-left: -100px;
  }

  @media (max-width: 1150px) {
    flex-basis: 460px;
    margin-left: -20px;
  }

  @media (max-width: 940px) {
    width: 100%;
    max-width: 680px;
    min-height: 550px;
    margin-left: 0;
    margin-right: 0;
  }
`;

const Description = styled.p`
  max-width: 600px;
  margin: 30px 0;

  @media (max-width: 600px) {
    font-size: 16px;
    margin: 20px 0;
  }
`;

const ButtonsWrapper = styled.div`
  margin-top: 10px;
  display: flex;
  align-items: flex-start;

  a {
    text-decoration: none;
  }

  @media (max-width: 900px) {
    flex-wrap: wrap;
  }

  @media (max-width: 400px) {
    & > * {
      width: 100%;
    }
  }
`;

const RowOfLogosContainer = styled(WidthContainer)`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledMainTitle = styled(MainTitle)`
  @media (min-width: 900px) {
    font-size: 68px;
  }
`;

const SidesSpace = styled.div`
  width: 30px;
  height: 60px;

  @media (max-width: 420px) {
    height: 0;
  }
`;

const LandingHeroSection = () => {
  const t = useTranslations();

  return (
    <Container>
      <StyledWidthContainer>
        <ContentSide>
          <div className="flex flex-col grow justify-center">
            <StyledMainTitle>{t('website.hero section title')}</StyledMainTitle>

            <Description>
              {t.rich('website.hero section description', {strong: (chunks) => <strong>{chunks}</strong>})}
            </Description>

            <ButtonsWrapper>
              <LandingButton
                href="/demo"
                onClick={() => plausible('Book Demo button clicked', {props: {place: 'Hero section'}})}
              >
                {t('website.book demo')}
              </LandingButton>

              <div className="w-4 h-4" />

              <LandingButton
                href="/videodemo"
                variant="secondary"
                onClick={() => plausible('See Demo button clicked', {props: {place: 'Hero section'}})}
              >
                {t('website.see demo video')}
              </LandingButton>
            </ButtonsWrapper>
          </div>
        </ContentSide>
        <SidesSpace />
        <FeatureSide>
          <HeroPhoneFeature />
        </FeatureSide>
      </StyledWidthContainer>

      <RowOfLogosContainer>
        <p className="text-lg mb-4 text-center">{t('website.company logos description')}</p>
        <RowOfLogos />
      </RowOfLogosContainer>
    </Container>
  );
};

export default LandingHeroSection;
